/*
 * Composant pour les actions js lié aux diagrammes circulaires
 */

import Swal from "sweetalert2/dist/sweetalert2";

export default class PieChart {

    constructor($view) {
        this.$view = $view;
        this.Init();
    }

    Init() {
        let self = this;

        if ($(".submitDiagramme")) {
            $(".submitDiagramme").on('click', function (event) {
                self.generate("Clore l'etude", "Etes-vous sur de vouloir clore cette étude ?")
            });
        }

        if ($(".submitRegenerateDiagramme")) {
            $(".submitRegenerateDiagramme").on('click', function (event) {
                self.generate("Regénérer les diagrammes", "Etes-vous sur de vouloir regénérer les diagrammes ?")
            });
        }
      
        
    }

    generate(titre, message) {
        Swal.fire({
            title: titre,
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                // afin de stocker les exports d'images des différents graphiques
                let dataFiles = [];
                let dataFilesName = [];

                // on récupère le premier graphique
                let repPopFemMasc = $('#repPopFemMasc').getKendoChart();

                // on export l'image
                repPopFemMasc.exportImage().done(function (data) {

                    // quand l'export de l'image est finit, on push le tableau
                    dataFiles.push(data);
                    dataFilesName.push("repPopFemMasc");

                    // deuxième graphique
                    let repPopAge = $('#repPopAge').getKendoChart();
                    repPopAge.exportImage().done(function (data2) {

                        // quand l'export de l'image est finit, on push le tableau
                        dataFiles.push(data2);
                        dataFilesName.push("repPopAge");

                        let evalRisk = $('#evalRisk').getKendoChart();
                        evalRisk.exportImage().done(function (data3) {

                            // quand l'export de l'image est finit, on push le tableau
                            dataFiles.push(data3);
                            dataFilesName.push("evalRisk");

                            let repPatientImc = $('#repPatientImc').getKendoChart();
                            repPatientImc.exportImage().done(function (data4) {

                                // quand l'export de l'image est finit, on push le tableau
                                dataFiles.push(data4);
                                dataFilesName.push("repPatientImc");

                                let repPatientContinence = $('#repPatientContinence').getKendoChart();
                                repPatientContinence.exportImage().done(function (data5) {
                                    // quand l'export de l'image est finit, on push le tableau
                                    dataFiles.push(data5);
                                    dataFilesName.push("repPatientContinence");

                                    let repEscarre = $('#repEscarre').getKendoChart();
                                    repEscarre.exportImage().done(function (data6) {

                                        // quand l'export de l'image est finit, on push le tableau
                                        dataFiles.push(data6);
                                        dataFilesName.push("repEscarre");

                                        // On va ajouter les valeurs récoltées au formulaire courant
                                        for (var i = 0; i < dataFiles.length; i++) {
                                            $('#form-chart').append('<input type="hidden" name="base64" value="' + dataFiles[i] + '" /> ');
                                        }

                                        for (var i = 0; i < dataFilesName.length; i++) {
                                            $('#form-chart').append('<input type="hidden" name="fileName" value="' + dataFilesName[i] + '" /> ');
                                        }

                                        // Puis on submit
                                        $('#form-chart').submit();
                                    });
                                });
                            });
                        });

                    }).fail(function () {
                        alert("Une erreur est survenue pendant l'export de l'image du graphique 2");
                    });

                }).fail(function () {
                    alert("Une erreur est survenue pendant l'export de l'image du graphique 1");
                });
            }
        });
    }

}