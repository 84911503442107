/*
 * Composant génrique d'action d'une grille (filters, current page, sorts, etc).
 * 
 * Pour fonctionner, mettre un id de grid unique | Ajouter l'event onDataBound sur la Grid Kendo 
 */

import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class GridComponent {

    constructor($view) {

        this.$view = $view;
        this.$sessionStorageKey = "GridOption";
        this.$saveFilter = this.$view.data('save-filter');
        this.Init();
    }

    Init() {
        let self = this;

        window.onDataBound = this.window_onDataBound;
        window.setGridOptions = this.window_setGridOptions;
        window.GetGridFilterValue = this.window_GetGridFilterValue;
        window.AddOrRemoveFilter = this.window_AddOrRemoveFilter;
        window.FirstDataBound = false;

        //Si on change de user via la fonction LogAs en SuperAdmin, on réinitialise les filtres
        this.$view.on("click", '.btConnectTo', (e) => {
            sessionStorage.clear();
        });

        //Popup de confirmation d'action
        this.$view.on("click", '.ConfirmPopup', (e) => {
            //On récupère de façon générique la Grid Kendo de la page
            var grid = $("div[data-role='grid']")[0];
            var gridName = grid.id;
            self.popupConfirmation(e.currentTarget.dataset, gridName)
        });

        if (this.$saveFilter === true) {
            $(function () {
                //On récupère de façon générique la Grid Kendo de la page
                var grid = $("div[data-role='grid']")[0];
                var gridName = grid.id;
                // Récupération de l'état de la Grid via le localStorage (filters, current page, sorts, etc).
                self.window_setGridOptions(gridName);
            });
        }

    }

    window_onDataBound(e) {
        // Afin de définir si on est sur la premier databound 
        if (window.FirstDataBound === false) {

            // Afin que ça n'arrive que sur le premier
            window.FirstDataBound = true;

            // On check si la méthode existe sur la page
            var fn = window["FirstDataBoundMethod"];

            // Et que c'est bien une fonction
            if (typeof fn === 'function') {
                // On éxécute la méthode
                window.FirstDataBoundMethod();
            }
        }

        var parent = e.sender.element[0].parentElement;
        var saveFilter = $(parent).data('save-filter');

        if (saveFilter === true) {
            //On récupère le nom de la Grid Kendo courrante
            var gridName = e.sender.element[0].id;
            //On récupère la Grid Kendo courrante
            var grid = $("#" + gridName).data("kendoGrid");

            //On stocke le state de la grid Kendo dans le sessionStorage (détruit à la fin d'une session de navigation et limité à l'onglet actif ) 
            //console.log(sessionStorage[gridName + "GridOption"]);
            sessionStorage[gridName + "GridOption"] = kendo.stringify(grid.getOptions());
        }
    }

    window_setGridOptions(gridName) {
        //On récupère le state de la Grid Kendo depuis le localStorage
        var options = sessionStorage[gridName + this.$sessionStorageKey];
        if (options) {
            $("#" + gridName).data("kendoGrid").setOptions(JSON.parse(options));
        }
    }

    // Permet de récupérer la valeur d'un filtre si il existe sinon renvoi nul
    window_GetGridFilterValue(grid, field) {
        var dataSource = grid.dataSource;
        var filters = null;
        if (dataSource.filter() != null) {
            filters = dataSource.filter().filters;
        }

        // On test d'abord la présence de filtre
        if (filters) {
            // On parcours le premier lvl de filtre
            for (var x = 0; x < filters.length; x++) {
                var temp = filters[x];
                if (temp.field == field) {
                    return temp.value;
                } else if (temp.filters) {
                    // Sinon on check si il n'y pas de deuxième lvl de filtre
                    for (var y = 0; y < temp.filters.length; y++) {
                        temp2 = temp.filters[y];
                        if (temp2.field == field) {
                            return temp2.value;
                        }
                    }
                }
            }
        }
        return null;
    }

    // Affiche une popup de confirmation personnalisable. Si l'utilisateur confirme un appel ajax est fait vers l'url donné en paramètre.
    popupConfirmation(data, gridName) {

        let type = "POST";
        if (data.type === "GET" || data.type === "POST") {
            type = data.type;
        }


        Swal.fire({
            title: data.titlemodal,
            text: data.textemodal,
            icon: data.iconmodal,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: type,
                    url: data.url,

                    success: function (data) {
                        // ON recharge la grille en cas de succès
                        $("#" + gridName).data("kendoGrid").dataSource.read();
                    },
                    error: function (data) {

                        toastr.options = {
                            timeOut: 0,
                            extendedTimeOut: 0
                        };

                        // En cas d'erreur on test si un detail est a affiché
                        if (data.responseJSON && data.responseJSON.detail) {
                            toastr.error(data.responseJSON.detail);
                        }
                        else {
                            toastr.error("Une erreur est survenue");
                        }

                    }
                });
            }
        })
    }

    window_AddOrRemoveFilter(grid, field, operator, value) {

        var newFilter = { field: field, operator: operator, value: value };
        var dataSource = grid.dataSource;
        var filters = null;
        if (dataSource.filter() != null) {
            filters = dataSource.filter().filters;
        }

        if (value && value.length > 0) {
            //Add filter
            if (filters == null) {
                filters = [newFilter];
            }
            else {
                var isNew = true;
                var index = 0;
                for (index = 0; index < filters.length; index++) {
                    if (filters[index].field == field) {
                        isNew = false;
                        break;
                    }
                }
                if (isNew) {
                    filters.push(newFilter);
                }
                else {
                    filters[index] = newFilter;
                }
            }
        }
        else {
            //Remove filter
            var removeIndex = -1;
            var removeIndex2 = -1;
            for (var x = 0; x < filters.length; x++) {
                var temp = filters[x];
                if (temp.field == field) {
                    removeIndex = x;
                    break;
                } else if (temp.filters) {
                    for (var y = 0; y < temp.filters.length; y++) {
                        temp2 = temp.filters[y];
                        if (temp2.field == field) {
                            removeIndex2 = y;
                            break;
                        }
                    }
                    if (removeIndex2 != -1)
                        temp.filters.splice(removeIndex2, 1);
                }
            }
            if (removeIndex != -1)
                filters.splice(removeIndex, 1);

        }
        dataSource.filter(filters);
    }
}