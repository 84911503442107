/*
 * Composant pour les actions js lié au detail d'une etude
 */

import toastr from 'toastr';
import Swal from "sweetalert2/dist/sweetalert2";

export default class EtudesDetails {

    constructor($view) {

        this.$view = $view;
        this.$AllDone = this.$view.find('#AllDone');
        this.$RestitutionBtn = this.$view.find('#RestitutionBtn');
        this.Init();
    }

    Init() {
        let self = this;

        this.$view.on("click", '#VisualizeDiagramBtn', (e) => {
            self.visualizeDiagram(self)
        });

        this.$RestitutionBtn.on("click", (e) => {
            self.generateRestit(self, e)
        });
    }

    // lance la visualisation des diagrammes
    visualizeDiagram(self) {
        if (self.$AllDone.data().questionnaireempty.toLowerCase() == "true") {
            toastr.error("Aucun questionnaire pour cette étude. Impossible de visualiser les diagrammes.");
        }
        else if (self.$AllDone.data().done.toLowerCase() == "false") {
            toastr.error("Tous les questionnaires de cette étude ne sont pas terminés. Impossible de visualiser les diagrammes.");
        } else {

            $.ajax({
                type: 'POST',
                url: '/Etudes/VisualizeDiagram',
                data: {
                    idEtude: self.$AllDone.data().id
                },

                success: function (data) {
                    if (data.result == 1) {
                        toastr.success(data.message);
                    }
                    else if (data.result == 3) {
                        window.location = '/Etudes/PieChart?restitutionId=' + data.restitutionId
                    }
                    else {
                        toastr.error(data.message);
                    }
                }
            });
        }
    }

    // Soit affiche popup de site pour choisir un site puis genere la restit ou genere la restit direct
    generateRestit(self, e) {
        // on a plusieurs sites, on affiche une popup pour en choisir un
        if (self.$RestitutionBtn.data().multisites == true) {
            Swal.fire({
                title: 'Sélectionnez un site',
                html: '<select id="dropdown" class="swal2-input">' +
                    self.$RestitutionBtn.data().sites +
                    '</select>',
                showCancelButton: true,
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler',
                preConfirm: () => {
                    // Récupérer la valeur sélectionnée dans la dropdown
                    return $('#dropdown').val();
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/Restitution/Index?id=" + self.$RestitutionBtn.data().etudeid +"&siteId=" + result.value;
                }
            });

        } else {
            // on a qu'un seul sit on gener direct la restit
            window.location.href = self.$RestitutionBtn.data().urlonesite;
        }
    }
}