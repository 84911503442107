/*
 * Class permettant d'afficher le nom du fichier upload dans l'input
 */

export default class CustomFileInput {

    constructor($view) {

        this.view = $view;

        this.Init();
    }

    Init() {
        var self = this;

        $(self.view).on("change", function () {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });

        $(".remove-image", $(self.view).parents('.form-group')).on("click", function () {
            $(self.view).val("");
            $(self.view).siblings(".custom-file-label").removeClass("selected").html("Aucun fichier selectionné");
            $('.image-preview', $(self.view).parents('.form-group')).hide();
        });
    }
}