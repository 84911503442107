/*
 * Composant pour les actions js lié a une étude
 */

export default class Etudes {

    constructor($view) {

        this.$view = $view;
        this.$allSites = this.$view.find('#SelectedSitesId');
        this.$allClients = this.$view.find('#ClientId');
        this.Init();
    }

    Init() {
        let self = this;

        this.$view.on("change", '#ClientId', (e) => {
            self.callChangeAllSites(self, e.currentTarget.value)
        });
    }

    callChangeAllSites(self, idDis) {
        $.ajax({
            type: 'POST',
            url: '/Etudes/ChangeAllSites',
            data: {
                idClient: idDis
            },

            success: function (data) {

                // recuperation du multiselect des sites
                let multiSelect = $("#SelectedSitesId").data("kendoMultiSelect");
                let multiData = multiSelect.dataSource.data();

                // On vide la liste de sites pour pouvoir ajouter que ceux qu'on veut
                multiData.empty()

                // push des nouveaux sites dans le multidata
                data.allSites.forEach(function (item) {
                    multiData.push({ Text: item.Label, Value: item.Id })
                });

                // remplissage avec les nouvelles data dans le multiselect
                multiSelect.dataSource.data(multiData);

            }
        });
    }
}