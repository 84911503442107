require('bedrock');

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import '@progress/kendo-ui'
import '@progress/kendo-ui/js/cultures/kendo.culture.fr.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';

import App from '6tm-components';
window.App = App;

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

import toastr from 'toastr';
window.toastr = toastr;


// Components
import customFileInput from './js/components/customFileInput';
import etudes from './js/components/etudes';
import gridComponent from './js/components/gridComponent';
import equipements from './js/components/equipements';
import polesService from './js/components/polesServices';
import etudesDetails from './js/components/etudesDetails';
import alert from './js/components/alert';
import pieChart from './js/components/pieChart';

// Pages
App.Kernel.registerComponent('CustomFileInput', customFileInput)
App.Kernel.registerComponent('GridComponent', gridComponent)
App.Kernel.registerComponent('Etudes', etudes)
App.Kernel.registerComponent('Equipements', equipements)
App.Kernel.registerComponent('PolesServices', polesService)
App.Kernel.registerComponent('EtudesDetails', etudesDetails)
App.Kernel.registerComponent('Alert', alert)
App.Kernel.registerComponent('PieChart', pieChart)

$(() => {
    App.Kernel.bindComponents($("body"));
});

import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.editor';
import '@progress/kendo-ui/js/kendo.filtercell';
import '@progress/kendo-ui/js/kendo.aspnetmvc';
import '@progress/kendo-ui/js/kendo.treelist';


import './scss/main.scss';